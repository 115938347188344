<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>

    <div class="row justify-content-center" v-else-if="userId">

      <div class="col-md-3">
        <div class="nav flex-column nav-pills mt-4" id="v-pills-tab" role="tablist"
             aria-orientation="vertical">

          <a class="nav-link active" id="v-pills-education-tab" data-bs-toggle="pill" href="#v-pills-education"
             role="tab" aria-controls="v-pills-education" aria-selected="false">
            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
            <span class="ms-2">Образование</span>
          </a>

          <a class="nav-link" id="v-pills-science-degree-tab" data-bs-toggle="pill" href="#v-pills-science-degree"
             role="tab" aria-controls="v-pills-science-degree" aria-selected="false">
            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
            <span class="ms-2">Научная степень</span>
          </a>

          <a class="nav-link" id="v-pills-job-tab" data-bs-toggle="pill" href="#v-pills-job" role="tab"
             aria-controls="v-pills-job" aria-selected="false">
            <i class="fa fa-briefcase" aria-hidden="true"></i>
            <span class="ms-2">Опыт работы</span>
          </a>

          <a class="nav-link" id="v-pills-languageskills-tab" data-bs-toggle="pill" href="#v-pills-languageskills"
             role="tab" aria-controls="v-pills-languageskills" aria-selected="false">
            <i class="fa fa-globe" aria-hidden="true"></i>
            <span class="ms-2">Языковые навыки</span>
          </a>

          <a class="nav-link" id="v-pills-researchwork-tab" data-bs-toggle="pill" href="#v-pills-researchwork"
             role="tab" aria-controls="v-pills-researchwork" aria-selected="false">
            <i class="fa fa-book" aria-hidden="true"></i>&nbsp;
            <span class="ms-2">НИР</span>
          </a>
        </div>
      </div>
      <div class="col-md-9">
        <div class="tab-content" id="v-pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-education" role="tabpanel"
               aria-labelledby="v-pills-education-tab">
            <EducationWithoutDocuments/>
          </div>
          <div class="tab-pane fade" id="v-pills-science-degree" role="tabpanel"
               aria-labelledby="v-pills-job-tab">
            <ScienceDegree/>
          </div>
          <div class="tab-pane fade" id="v-pills-job" role="tabpanel"
               aria-labelledby="v-pills-job-tab">
            <Job/>
          </div>
          <div class="tab-pane fade" id="v-pills-languageskills" role="tabpanel"
               aria-labelledby="v-pills-languageskills-tab">
            <LanguageSkills/>
          </div>
          <div class="tab-pane fade" id="v-pills-researchwork" role="tabpanel"
               aria-labelledby="v-pills-researchwork-tab">
            <ResearchWorks/>
          </div>
        </div>
      </div>


    </div>
    <div class="text-center mt-3" v-else>Преподаватель не найден</div>

  </div>
</template>

<script>
  import EducationWithoutDocuments from '@/components/vacancy-resume-info/education/EducationWithoutDocuments.vue'
  import ScienceDegree from '@/components/vacancy-resume-info/science-degree/ScienceDegree.vue'
  import Job from '@/components/vacancy-resume-info/job/Job.vue'
  import LanguageSkills from '@/components/vacancy-resume-info/language-skills/LanguageSkills.vue'
  import ResearchWorks from '@/components/vacancy-resume-info/research-work/ResearchWorks.vue'

  import {mapActions, mapState} from "vuex"

  export default {
    name: 'UserInfo',
    data() {
      return {
        userId: null,
        loading: true
      }
    },
    components: {
      EducationWithoutDocuments,
      ScienceDegree,
      Job,
      LanguageSkills,
      ResearchWorks
    },
    methods: {
      ...mapActions('userinfo', [
        'GET_USER_EDUCATION_DATA', 'GET_USER_QUALIFICATION_DATA', 'GET_USER_EDUCATION_FILES',
        'GET_USER_JOB_DATA', 'GET_USER_LANG_DATA', 'GET_USER_ARTICLES',
        'GET_SCIENCE_DEGREE_BY_USER']),
      ...mapActions('education', ['getLanguages']),
      ...mapActions('languageskills', ['GET_LANGUAGES', 'GET_LANGUAGES_LEVEL', 'GET_LANGUAGE_SKILLS_DATA']),
      ...mapActions('researchwork', ["GET_RESEARCH_WORK_TYPE", "GET_SUB_RESEARCH_WORK_TYPE"]),
    },
    async mounted() {
      let userId = sessionStorage.getItem('USER')

      if (userId) {
        await this.getLanguages()

        await this.GET_LANGUAGES()
        await this.GET_LANGUAGES_LEVEL()
        await this.GET_LANGUAGE_SKILLS_DATA()

        await this.GET_RESEARCH_WORK_TYPE()
        await this.GET_SUB_RESEARCH_WORK_TYPE()

        await this.GET_USER_EDUCATION_DATA(userId)
        await this.GET_USER_QUALIFICATION_DATA(userId)
        await this.GET_USER_JOB_DATA(userId)
        await this.GET_USER_LANG_DATA(userId)
        await this.GET_USER_ARTICLES(userId)
        await this.GET_SCIENCE_DEGREE_BY_USER(userId)
      }

      this.userId = userId
      this.loading = false
    }
  }
</script>