<template>

  <div class="row">
    <nav>
      <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-education-tab" data-bs-toggle="tab"
                data-bs-target="#nav-education"
                type="button" role="tab" aria-controls="nav-education" aria-selected="true"><i
            class="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp; Образование
        </button>
        <button class="nav-link" id="nav-qualification-tab" data-bs-toggle="tab"
                data-bs-target="#nav-qualification"
                type="button" role="tab" aria-controls="nav-qualification" aria-selected="false"><i
            class="fa fa-line-chart" aria-hidden="true"></i>&nbsp; Повышение квалификации
        </button>
      </div>
    </nav>

    <div class="form-block">

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-education" role="tabpanel"
             aria-labelledby="nav-education-tab">
          <EducationTab/>
        </div>
        <div class="tab-pane fade" id="nav-qualification" role="tabpanel"
             aria-labelledby="nav-qualification-tab">
          <QualificationTab/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import EducationTab from '@/components/vacancy-resume-info/education/EducationTab.vue'
  import QualificationTab from '@/components/vacancy-resume-info/education/QualificationTab.vue'

  export default {
    name: 'EducationWithoutDocuments',
    components: {
      EducationTab,
      QualificationTab
    }
  }
</script>